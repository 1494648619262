import React from "react"
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux"

import { onChange } from "../../redux/registration/registrationAction"
import CheckboxInput from "./welcome/checkboxInput"
import { validTab } from "../../redux/tabs/tabsAction"
import AlertCategory from "./alertCategory/alertCategory"
import HelpUr from "../../components/help/help"
import { isDefined } from "../../utils/data"


const YourData = ({ prevTab, nextTab }) => {
    const dispatch = useDispatch()
    const { information, user } = useSelector(({ registrationReducer }) => registrationReducer)

    const disabledInput = isDefined(user)

    const onClickNext = (e) => {
        e.preventDefault()

        if (information.registered_cvg.value === true || information.registered_cvg.value === "1") {
            dispatch(validTab("yourDataTab"))
            nextTab()
        }
    }

    return (
        <div className={"content yourData col-10 mx-auto txt-left"}>
            <AlertCategory />

            <p className={"title"}>How we use your data?</p>

            <p className={"small txt-left"}>
                The Union will use the personal data from this form only in direct relation to your attendance at the Union World Conference on Lung Health 2022 in Seville (such as, for registration, programme updates and the mobile app). We will not share your data for any commercial reason.
            </p>
            <p className={"small txt-left"}>
                Please note: During the conference, exhibitors may ask if they can scan your badge. If your badge is scanned, certain details of your registration profile will be shared with the exhibitor. Be aware that by allowing your badge to be scanned you consent to sharing this information.
            </p>
            <p className={"small txt-left"}>
                By entering the event premises, delegates give their permission to be photographed or recorded by official photographers, videographers and attending media of the Union World Conference on Lung Health 2022. Delegates consent to their images and video being reproduced for subsequent use in the media, webcasts, internal and external promotional purposes, advertising and inclusion on websites and social media. Images are shared publicly with open access for use.
            </p>
            <p className={"small txt-left"}>
                This is in line with The Union's <a href="https://www.theunion.org/privacy-policy" target="_blank">Privacy Policy</a>. You can change your details or this consent at any time by emailing <a href={"mailto:privacy@theunion.org"}>privacy@theunion.org</a>.
            </p>

            <CheckboxInput placeholder={"I understand this is how The Union will use my data *"}
                onChange={e => dispatch(onChange(e, true))}
                name={"registered_cvg"}
                value={information.registered_cvg.value}
                disabled={information.registered_cvg.value === true || information.registered_cvg.value === "1"} />


            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
                <small className={"help"}><HelpUr /></small>
                <button className={"sr-btn-next btn btn-info"} disabled={information.registered_cvg.value === false || information.registered_cvg.value === "0"} onClick={onClickNext}>Next</button>
            </div>
        </div>
    )
}

YourData.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default YourData
